import { useCookies } from 'react-cookie'
import { useAtomValue } from 'jotai'

import { detachKeepJobsApi, getJobsByIdsApi, getKeepJobsApi, postKeepJobsApi } from '@/api/jobApi'
import { authMeAtom } from '@/globalState/auth'
import { useGlobalBadges } from '@/hooks/talent/useGlobalBadges'
import { useToastWrapper } from '@/modules/hooks/useToastWrapper'
import { BulkKeepJobRequestJobIdsInner } from '@/oas/talent/api'

export interface KeptJobsCookie {
  jobId: string
  keepTime: string
}

export const useKeepJob = () => {
  const meAtom = useAtomValue(authMeAtom)
  const { error } = useToastWrapper()
  const [cookies, setCookie] = useCookies(['keptJobs'])
  const { keepCount, localMutateKeepCount } = useGlobalBadges()
  /**
   * 未認証時にjobIdの配列を渡してキープしている求人一覧取得
   * @param jobIds
   * @returns Promise<JobList | undefined>
   */
  const getKeepJobsForNotAuthentication = async (isAuthenticated: boolean, jobIds: string[]) => {
    const res = await getJobsByIdsApi(isAuthenticated, jobIds)
      .then((res) => {
        return res.data
      })
      .catch(() => {
        error('求人の取得に失敗しました。画面を更新して再度お試しください。')
        return undefined
      })
    return res
  }

  /**
   * 認証時の求人取得
   * @param page
   * @returns Promise<JobList | undefined>
   */
  const getKeepJobs = async (page?: number) => {
    const res = await getKeepJobsApi(page)
      .then((res) => {
        return res.data
      })
      .catch(() => {
        error('求人の取得に失敗しました。画面を更新して再度お試しください。')
        return undefined
      })
    return res
  }

  /**
   * 求人をkeepする
   * @param jobIds
   * @returns boolean trueがsuccess
   */
  const postKeepJobs = async (jobIds: BulkKeepJobRequestJobIdsInner[]) => {
    localMutateKeepCount((keepCount ?? 0) + 1)
    const res = await postKeepJobsApi({
      job_ids: jobIds,
    })
      .then(() => {
        return true
      })
      .catch(() => {
        localMutateKeepCount((keepCount ?? 0) - 1)
        return false
      })
    return res
  }

  /**
   * お気に入りを外す
   * @param jobIds
   * @returns
   */
  const detachKeepJobs = async (jobIds: string[]) => {
    localMutateKeepCount((keepCount ?? 0) - 1)
    const res = await detachKeepJobsApi({
      job_ids: jobIds,
    })
      .then(() => {
        return true
      })
      .catch(() => {
        localMutateKeepCount((keepCount ?? 0) + 1)
        return false
      })
    return res
  }

  /**
   * キープした求人をCookieに保存する処理
   * @param currentValues
   * @param jobIds
   */
  const setCookieForKeepJobs = async (
    keepJobs: KeptJobsCookie,
    currentValues: KeptJobsCookie[],
  ) => {
    let formatValues: KeptJobsCookie[] = []
    if (currentValues.some((v) => v.jobId === keepJobs.jobId)) {
      currentValues.filter((v) => v.jobId !== keepJobs.jobId).unshift(keepJobs)
      formatValues = [...currentValues]
    } else {
      formatValues = [keepJobs, ...currentValues]
    }
    localMutateKeepCount(formatValues.length)
    // 3ヶ月の期間保存する
    setCookie('keptJobs', JSON.stringify(formatValues), {
      maxAge: 90 * 24 * 60 * 60,
      path: '/',
    })
  }

  /**
   * 未認証→Cookieに保存
   * 認証済み→APIで保存
   */
  const onKeep = async (jobId: string, value: boolean) => {
    if (meAtom.me) {
      if (value) {
        // Why: ログイン済みの場合は、保存時刻はサーバーで得る
        await postKeepJobs([{ job_id: jobId, kept_at: null }]).then((res) => {
          if (!res) {
            error('お気に入りの保存に失敗しました。画面を更新して再度お試しください。')
          }
        })
      } else {
        await detachKeepJobs([jobId]).then((res) => {
          if (!res) {
            error('お気に入りの保存に失敗しました。画面を更新して再度お試しください。')
          }
        })
      }
    } else {
      const cookie = cookies.keptJobs ? (cookies.keptJobs as unknown as KeptJobsCookie[]) : []
      if (cookie.length === 0) {
        const keepJobs: KeptJobsCookie = {
          jobId: jobId,
          keepTime: new Date().toISOString(),
        }
        setCookieForKeepJobs(keepJobs, [])
        return
      }
      // 値を見てkeepしたならCookieに保存、keepを外したならCookieから削除
      if (value) {
        localMutateKeepCount((keepCount ?? 0) + 1)
        const keepJob: KeptJobsCookie = {
          jobId: jobId,
          keepTime: new Date().toISOString(),
        }
        setCookieForKeepJobs(keepJob, cookie)
        return
      }
      localMutateKeepCount((keepCount ?? 0) - 1)
      const filterKeepJobs = cookie.filter((v) => v.jobId !== jobId)
      setCookie('keptJobs', JSON.stringify(filterKeepJobs), {
        maxAge: 90 * 24 * 60 * 60,
        path: '/',
      })
    }
  }

  return {
    getKeepJobs,
    getKeepJobsForNotAuthentication,
    postKeepJobs,
    detachKeepJobs,
    onKeep,
  }
}
