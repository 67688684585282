import { GetServerSidePropsContext } from 'next'

import { axiosInstance } from '@/api/axiosInstance'
import serverSideLogger from '@/modules/utils/serverSideLogger'
import { AuthApi as AuthApiForPublic } from '@/oas/public/api'
import { AuthApi, RegistrationRequest } from '@/oas/talent/api'

import { getPublicApiUrl, getTalentApiUrl } from './apiHostNameSwitcher'

const authApi = new AuthApi(undefined, getTalentApiUrl(), axiosInstance)
const authApiToPublic = new AuthApiForPublic(undefined, getPublicApiUrl(), axiosInstance)

export const getMeApi = (token?: string) => {
  return authApi.getMe({
    headers: token
      ? {
          Cookie: `firebase-session-cookie=${token}`,
        }
      : {},
    withCredentials: true,
  })
}

export const getMeApiForSSR = (token: string, context: GetServerSidePropsContext) => {
  const cookieName = 'firebase-session-cookie'
  return authApi
    .getMe({
      headers: {
        Cookie: `firebase-session-cookie=${token}`,
      },
      withCredentials: true,
    })
    .catch((err) => {
      context.res.setHeader(
        'Set-Cookie',
        `${cookieName}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT`,
      )
      serverSideLogger.error({
        msg: {
          err,
        },
      })
    })
}

export const loginApi = (token: string) => {
  return authApi.login({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    withCredentials: true,
  })
}

/**
 * What: Laravelのセッションパラメータ使用開始のためにセッションIDのCookieを取得するAPI
 * Why: LINEログインに外部遷移フローがあり、セッションパラメータを使用する必要があるため
 */
export const sessionInitializeApi = () => {
  return authApi.sessionInitialize({
    withCredentials: true,
  })
}

export const lineLoginApi = () => {
  return authApi.lineLogin({
    withCredentials: true,
  })
}

export const lineAddLoginMethodApi = () => {
  return authApi.lineAddLoginMethod({
    withCredentials: true,
  })
}

export const registrationApi = (registrationRequest: RegistrationRequest, token: string) => {
  return authApi.registration(registrationRequest, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    withCredentials: true,
  })
}

export const addSmsLoginMethodApi = (token: string) => {
  return authApi.addSmsLoginMethod({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    withCredentials: true,
  })
}

export const logoutApi = () => {
  return authApi.logout({
    withCredentials: true,
  })
}

export const deleteUserApi = () => {
  return authApi.authDeleteUser({
    withCredentials: true,
  })
}

export const checkValidateContactApi = (email: string, phoneNumber: string) => {
  return authApiToPublic.validateContacts(email, phoneNumber, {
    withCredentials: true,
  })
}

export const fetchGlobalAlertsApi = () => {
  return authApi.fetchGlobalAlerts({
    withCredentials: true,
  })
}

export const fetchGlobalBadgesApi = () => {
  return authApi.fetchGlobalBadges({
    withCredentials: true,
  })
}
