import { atom } from 'jotai'

import { MeTalent } from '@/oas/talent/api'

// login user
interface LoginUser {
  me: MeTalent | null
  loading: boolean
}
export const initialAuthState = {
  me: null,
  loading: true,
} as const

export const authMeAtom = atom<LoginUser | typeof initialAuthState>(initialAuthState)
authMeAtom.debugLabel = 'authMe'
