/* tslint:disable */
/* eslint-disable */
/**
 * z-career Public API
 * z-career Public API.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration'
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base'

/**
 *
 * @export
 * @interface Address
 */
export interface Address {
  /**
   * AgentBankDBでのaddress.id
   * @type {number}
   * @memberof Address
   */
  ab_address_id: number
  /**
   *
   * @type {MediumMasterListItem}
   * @memberof Address
   */
  prefecture: MediumMasterListItem
  /**
   * 勤務地_住所
   * @type {string}
   * @memberof Address
   */
  address: string | null
  /**
   * 勤務地_勤務地詳細
   * @type {string}
   * @memberof Address
   */
  detail: string | null
  /**
   * 勤務地_最寄駅
   * @type {Array<Station>}
   * @memberof Address
   */
  stations: Array<Station> | null
  /**
   * 検索条件に使用されたかどうか
   * @type {boolean}
   * @memberof Address
   */
  is_searched?: boolean | null
}
/**
 * 都道府県ごとの勤務地_住所のリスト。検索に指定された都道府県の勤務地は先頭・都道府県ID昇順・都道府県ID重複なし。
 * @export
 * @interface AddressesByPrefecture
 */
export interface AddressesByPrefecture {
  /**
   * 都道府県ID
   * @type {number}
   * @memberof AddressesByPrefecture
   */
  prefecture_master_id: number
  /**
   *
   * @type {Array<Address>}
   * @memberof AddressesByPrefecture
   */
  addresses: Array<Address>
}
/**
 * 非公開の求人情報
 * @export
 * @interface ClosedJob
 */
export interface ClosedJob {
  /**
   * 求人ID
   * @type {string}
   * @memberof ClosedJob
   */
  id: string
  /**
   * 求人公開フラグ
   * @type {boolean}
   * @memberof ClosedJob
   */
  is_job_published: boolean
  /**
   * 採用企業名
   * @type {string}
   * @memberof ClosedJob
   */
  recruitment_company_name: string
  /**
   * 求人タイトル
   * @type {string}
   * @memberof ClosedJob
   */
  job_title: string
}
/**
 *
 * @export
 * @interface Count
 */
export interface Count {
  /**
   * 検索にHitする求人数を取得するAPI
   * @type {number}
   * @memberof Count
   */
  count: number
}
/**
 * sitemap.xmlの生成のためにNext.jsに渡したい値を一括で提供する
 * @export
 * @interface ForSitemap
 */
export interface ForSitemap {
  /**
   * 公開している全求人のID列
   * @type {Array<string>}
   * @memberof ForSitemap
   */
  all_job_ids: Array<string>
  /**
   * 全都道府県のID列
   * @type {Array<number>}
   * @memberof ForSitemap
   */
  prefecture_master_ids: Array<number>
  /**
   * 全求人カテゴリのID列
   * @type {Array<number>}
   * @memberof ForSitemap
   */
  job_category_master_ids: Array<number>
  /**
   * こだわり条件のID列
   * @type {Array<number>}
   * @memberof ForSitemap
   */
  particular_condition_master_ids: Array<number>
}
/**
 *
 * @export
 * @interface GetJob200Response
 */
export interface GetJob200Response {
  /**
   * 求人ID
   * @type {string}
   * @memberof GetJob200Response
   */
  id: string
  /**
   *
   * @type {Array<AddressesByPrefecture>}
   * @memberof GetJob200Response
   */
  addresses_by_prefectures: Array<AddressesByPrefecture>
  /**
   *
   * @type {RecruitmentCompany}
   * @memberof GetJob200Response
   */
  recruitment_company: RecruitmentCompany
  /**
   * 最終更新日
   * @type {string}
   * @memberof GetJob200Response
   */
  ab_modified_at?: string
  /**
   * 内定率
   * @type {number}
   * @memberof GetJob200Response
   */
  job_offer_rate: number
  /**
   * 求人公開フラグ
   * @type {boolean}
   * @memberof GetJob200Response
   */
  is_job_published: boolean
  /**
   *
   * @type {MediumMasterListItem}
   * @memberof GetJob200Response
   */
  job_category?: MediumMasterListItem
  /**
   * 正社員経験なしOKフラグ
   * @type {boolean}
   * @memberof GetJob200Response
   */
  is_allowed_no_prior_full_time_experience: boolean
  /**
   * 上場企業フラグ
   * @type {boolean}
   * @memberof GetJob200Response
   */
  is_listed_company: boolean
  /**
   * 車通勤OKフラグ
   * @type {boolean}
   * @memberof GetJob200Response
   */
  is_allowed_to_commute_by_car: boolean
  /**
   * 転勤なしフラグ
   * @type {boolean}
   * @memberof GetJob200Response
   */
  is_no_need_to_relocate: boolean
  /**
   * 服装自由フラグ
   * @type {boolean}
   * @memberof GetJob200Response
   */
  is_dress_code_free: boolean
  /**
   * 社員寮ありフラグ
   * @type {boolean}
   * @memberof GetJob200Response
   */
  has_dormitory: boolean
  /**
   * 年間休日120日以上フラグ
   * @type {boolean}
   * @memberof GetJob200Response
   */
  is_more_than_120_days_off_per_year: boolean
  /**
   * 土日祝休みフラグ
   * @type {boolean}
   * @memberof GetJob200Response
   */
  has_weekends_and_national_holiday: boolean
  /**
   * 残業時間20時間以内フラグ
   * @type {boolean}
   * @memberof GetJob200Response
   */
  is_overtime_under_20: boolean
  /**
   * 時短勤務可フラグ
   * @type {boolean}
   * @memberof GetJob200Response
   */
  is_available_to_reduce_working_time: boolean
  /**
   * 求人タイトル
   * @type {string}
   * @memberof GetJob200Response
   */
  job_title: string
  /**
   * 年収(上限)
   * @type {number}
   * @memberof GetJob200Response
   */
  max_income: number
  /**
   * 年収(下限)
   * @type {number}
   * @memberof GetJob200Response
   */
  min_income: number
  /**
   * 月収(上限)
   * @type {number}
   * @memberof GetJob200Response
   */
  max_monthly_income: number | null
  /**
   * 月収(下限)
   * @type {number}
   * @memberof GetJob200Response
   */
  min_monthly_income: number | null
  /**
   * 仕事内容
   * @type {string}
   * @memberof GetJob200Response
   */
  explanation_of_job?: string
  /**
   * 仕事の醍醐味
   * @type {string}
   * @memberof GetJob200Response
   */
  description_of_attractiveness?: string | null
  /**
   * 必須要件
   * @type {string}
   * @memberof GetJob200Response
   */
  description_of_entry_requirement?: string | null
  /**
   * 活躍できる経験
   * @type {string}
   * @memberof GetJob200Response
   */
  description_of_expected_experience?: string | null
  /**
   *
   * @type {MediumMasterListItem}
   * @memberof GetJob200Response
   */
  final_education?: MediumMasterListItem
  /**
   *
   * @type {MediumMasterListItem}
   * @memberof GetJob200Response
   */
  driver_license?: MediumMasterListItem
  /**
   * 給与
   * @type {string}
   * @memberof GetJob200Response
   */
  description_of_salary?: string
  /**
   *
   * @type {MediumMasterListItem}
   * @memberof GetJob200Response
   */
  employment_type?: MediumMasterListItem
  /**
   * 勤務時間
   * @type {string}
   * @memberof GetJob200Response
   */
  description_of_working_time?: string
  /**
   * 時短勤務詳細
   * @type {string}
   * @memberof GetJob200Response
   */
  short_time_work_detail?: string | null
  /**
   * 残業時間
   * @type {string}
   * @memberof GetJob200Response
   */
  average_overtime_work_per_month?: string | null
  /**
   * 福利厚生
   * @type {string}
   * @memberof GetJob200Response
   */
  description_of_benefit_system?: string
  /**
   * 年間休日
   * @type {string}
   * @memberof GetJob200Response
   */
  annual_holidays?: string
  /**
   * 休日・休暇
   * @type {string}
   * @memberof GetJob200Response
   */
  description_of_holidays?: string
  /**
   * 試用期間
   * @type {boolean}
   * @memberof GetJob200Response
   */
  has_probationary_period: boolean
  /**
   * 試用期間詳細
   * @type {string}
   * @memberof GetJob200Response
   */
  probation_detail?: string | null
  /**
   * 受動喫煙対策
   * @type {string}
   * @memberof GetJob200Response
   */
  measures_against_passive_smoking?: string
  /**
   * 受動喫煙対策詳細
   * @type {string}
   * @memberof GetJob200Response
   */
  measures_against_passive_smoking_detail?: string | null
  /**
   * ユーザーが応募したことがあるかのフラグ。未ログインの場合は一律NULL。
   * @type {boolean}
   * @memberof GetJob200Response
   */
  is_entered?: boolean | null
  /**
   * ユーザーがお気に入りしているかのフラグ。未ログインの場合は一律NULL。
   * @type {boolean}
   * @memberof GetJob200Response
   */
  is_kept?: boolean | null
  /**
   * 採用企業名
   * @type {string}
   * @memberof GetJob200Response
   */
  recruitment_company_name: string
}
/**
 * 求人情報
 * @export
 * @interface Job
 */
export interface Job {
  /**
   * 求人ID
   * @type {string}
   * @memberof Job
   */
  id: string
  /**
   *
   * @type {Array<AddressesByPrefecture>}
   * @memberof Job
   */
  addresses_by_prefectures: Array<AddressesByPrefecture>
  /**
   *
   * @type {RecruitmentCompany}
   * @memberof Job
   */
  recruitment_company: RecruitmentCompany
  /**
   * 最終更新日
   * @type {string}
   * @memberof Job
   */
  ab_modified_at?: string
  /**
   * 内定率
   * @type {number}
   * @memberof Job
   */
  job_offer_rate: number
  /**
   * 求人公開フラグ
   * @type {boolean}
   * @memberof Job
   */
  is_job_published: boolean
  /**
   *
   * @type {MediumMasterListItem}
   * @memberof Job
   */
  job_category?: MediumMasterListItem
  /**
   * 正社員経験なしOKフラグ
   * @type {boolean}
   * @memberof Job
   */
  is_allowed_no_prior_full_time_experience: boolean
  /**
   * 上場企業フラグ
   * @type {boolean}
   * @memberof Job
   */
  is_listed_company: boolean
  /**
   * 車通勤OKフラグ
   * @type {boolean}
   * @memberof Job
   */
  is_allowed_to_commute_by_car: boolean
  /**
   * 転勤なしフラグ
   * @type {boolean}
   * @memberof Job
   */
  is_no_need_to_relocate: boolean
  /**
   * 服装自由フラグ
   * @type {boolean}
   * @memberof Job
   */
  is_dress_code_free: boolean
  /**
   * 社員寮ありフラグ
   * @type {boolean}
   * @memberof Job
   */
  has_dormitory: boolean
  /**
   * 年間休日120日以上フラグ
   * @type {boolean}
   * @memberof Job
   */
  is_more_than_120_days_off_per_year: boolean
  /**
   * 土日祝休みフラグ
   * @type {boolean}
   * @memberof Job
   */
  has_weekends_and_national_holiday: boolean
  /**
   * 残業時間20時間以内フラグ
   * @type {boolean}
   * @memberof Job
   */
  is_overtime_under_20: boolean
  /**
   * 時短勤務可フラグ
   * @type {boolean}
   * @memberof Job
   */
  is_available_to_reduce_working_time: boolean
  /**
   * 求人タイトル
   * @type {string}
   * @memberof Job
   */
  job_title: string
  /**
   * 年収(上限)
   * @type {number}
   * @memberof Job
   */
  max_income: number
  /**
   * 年収(下限)
   * @type {number}
   * @memberof Job
   */
  min_income: number
  /**
   * 月収(上限)
   * @type {number}
   * @memberof Job
   */
  max_monthly_income: number | null
  /**
   * 月収(下限)
   * @type {number}
   * @memberof Job
   */
  min_monthly_income: number | null
  /**
   * 仕事内容
   * @type {string}
   * @memberof Job
   */
  explanation_of_job?: string
  /**
   * 仕事の醍醐味
   * @type {string}
   * @memberof Job
   */
  description_of_attractiveness?: string | null
  /**
   * 必須要件
   * @type {string}
   * @memberof Job
   */
  description_of_entry_requirement?: string | null
  /**
   * 活躍できる経験
   * @type {string}
   * @memberof Job
   */
  description_of_expected_experience?: string | null
  /**
   *
   * @type {MediumMasterListItem}
   * @memberof Job
   */
  final_education?: MediumMasterListItem
  /**
   *
   * @type {MediumMasterListItem}
   * @memberof Job
   */
  driver_license?: MediumMasterListItem
  /**
   * 給与
   * @type {string}
   * @memberof Job
   */
  description_of_salary?: string
  /**
   *
   * @type {MediumMasterListItem}
   * @memberof Job
   */
  employment_type?: MediumMasterListItem
  /**
   * 勤務時間
   * @type {string}
   * @memberof Job
   */
  description_of_working_time?: string
  /**
   * 時短勤務詳細
   * @type {string}
   * @memberof Job
   */
  short_time_work_detail?: string | null
  /**
   * 残業時間
   * @type {string}
   * @memberof Job
   */
  average_overtime_work_per_month?: string | null
  /**
   * 福利厚生
   * @type {string}
   * @memberof Job
   */
  description_of_benefit_system?: string
  /**
   * 年間休日
   * @type {string}
   * @memberof Job
   */
  annual_holidays?: string
  /**
   * 休日・休暇
   * @type {string}
   * @memberof Job
   */
  description_of_holidays?: string
  /**
   * 試用期間
   * @type {boolean}
   * @memberof Job
   */
  has_probationary_period: boolean
  /**
   * 試用期間詳細
   * @type {string}
   * @memberof Job
   */
  probation_detail?: string | null
  /**
   * 受動喫煙対策
   * @type {string}
   * @memberof Job
   */
  measures_against_passive_smoking?: string
  /**
   * 受動喫煙対策詳細
   * @type {string}
   * @memberof Job
   */
  measures_against_passive_smoking_detail?: string | null
  /**
   * ユーザーが応募したことがあるかのフラグ。未ログインの場合は一律NULL。
   * @type {boolean}
   * @memberof Job
   */
  is_entered?: boolean | null
  /**
   * ユーザーがお気に入りしているかのフラグ。未ログインの場合は一律NULL。
   * @type {boolean}
   * @memberof Job
   */
  is_kept?: boolean | null
}
/**
 * talentの求人一覧のレスポンス
 * @export
 * @interface JobList
 */
export interface JobList {
  /**
   *
   * @type {Array<Job>}
   * @memberof JobList
   */
  data: Array<Job>
  /**
   *
   * @type {ListMeta}
   * @memberof JobList
   */
  meta: ListMeta
}
/**
 * master情報のレスポンス
 * @export
 * @interface LargeMasterListItem
 */
export interface LargeMasterListItem {
  /**
   * カテゴリ名
   * @type {string}
   * @memberof LargeMasterListItem
   */
  category: string
  /**
   *
   * @type {Array<MediumMasterListItem>}
   * @memberof LargeMasterListItem
   */
  items: Array<MediumMasterListItem>
}
/**
 * 参考リンク: https://developers.line.biz/ja/reference/messaging-api/#webhook-event-object
 * @export
 * @interface LineWebhookEvent
 */
export interface LineWebhookEvent {
  /**
   *
   * @type {string}
   * @memberof LineWebhookEvent
   */
  type: string
  /**
   *
   * @type {number}
   * @memberof LineWebhookEvent
   */
  timestamp: number
  /**
   *
   * @type {string}
   * @memberof LineWebhookEvent
   */
  mode: string
  /**
   *
   * @type {string}
   * @memberof LineWebhookEvent
   */
  webhookEventId: string
  /**
   *
   * @type {LineWebhookEventDeliveryContext}
   * @memberof LineWebhookEvent
   */
  deliveryContext: LineWebhookEventDeliveryContext
  /**
   *
   * @type {LineWebhookEventSource}
   * @memberof LineWebhookEvent
   */
  source?: LineWebhookEventSource
}
/**
 *
 * @export
 * @interface LineWebhookEventDeliveryContext
 */
export interface LineWebhookEventDeliveryContext {
  /**
   *
   * @type {boolean}
   * @memberof LineWebhookEventDeliveryContext
   */
  isRedelivery: boolean
}
/**
 *
 * @export
 * @interface LineWebhookEventSource
 */
export interface LineWebhookEventSource {
  /**
   *
   * @type {string}
   * @memberof LineWebhookEventSource
   */
  type?: string
  /**
   *
   * @type {string}
   * @memberof LineWebhookEventSource
   */
  userId?: string
  /**
   *
   * @type {string}
   * @memberof LineWebhookEventSource
   */
  groupId?: string
  /**
   *
   * @type {string}
   * @memberof LineWebhookEventSource
   */
  roomId?: string
}
/**
 * LINEからのWebhook Requestのフィールド定義、参考リンク: https://developers.line.biz/ja/reference/messaging-api/#request-body
 * @export
 * @interface LineWebhookRequest
 */
export interface LineWebhookRequest {
  /**
   * イベントが送信されたボットのチャンネルID、上掲リンクを参考のこと
   * @type {string}
   * @memberof LineWebhookRequest
   */
  destination: string
  /**
   * 参考リンク: https://developers.line.biz/ja/reference/messaging-api/#webhook-event-object
   * @type {Array<LineWebhookEvent>}
   * @memberof LineWebhookRequest
   */
  events: Array<LineWebhookEvent>
}
/**
 *
 * @export
 * @interface ListMeta
 */
export interface ListMeta {
  /**
   *
   * @type {Pagination}
   * @memberof ListMeta
   */
  pagination: Pagination
}
/**
 * メンテナンス状況
 * @export
 * @interface MaintenanceStatus
 */
export interface MaintenanceStatus {
  /**
   * メンテナンス中であるか
   * @type {boolean}
   * @memberof MaintenanceStatus
   */
  service_is_in_maintenance: boolean
  /**
   * 終了予定時刻
   * @type {string}
   * @memberof MaintenanceStatus
   */
  scheduled_end_time: string | null
}
/**
 * master情報（selectに入れる値）
 * @export
 * @interface MediumMasterListItem
 */
export interface MediumMasterListItem {
  /**
   * masterテーブルのID
   * @type {number}
   * @memberof MediumMasterListItem
   */
  id: number
  /**
   * 表示名
   * @type {string}
   * @memberof MediumMasterListItem
   */
  label: string
  /**
   * 順番
   * @type {number}
   * @memberof MediumMasterListItem
   */
  order?: number | null
}
/**
 *
 * @export
 * @interface Pagination
 */
export interface Pagination {
  /**
   * 総件数
   * @type {number}
   * @memberof Pagination
   */
  total: number
  /**
   * 1ページあたり件数
   * @type {number}
   * @memberof Pagination
   */
  per_page: number
  /**
   * 現在のページの最初の項目の番号
   * @type {number}
   * @memberof Pagination
   */
  first_item_count?: number | null
  /**
   * 現在のページの最後の項目の番号
   * @type {number}
   * @memberof Pagination
   */
  last_item_count?: number | null
  /**
   * 現在のページ数
   * @type {number}
   * @memberof Pagination
   */
  current_page: number
  /**
   * 総ページ数
   * @type {number}
   * @memberof Pagination
   */
  total_pages: number
}
/**
 * 都道府県（求人数付き）
 * @export
 * @interface PrefectureWithJobCount
 */
export interface PrefectureWithJobCount {
  /**
   * region_master_id
   * @type {number}
   * @memberof PrefectureWithJobCount
   */
  region_master_id: number
  /**
   * master_prefectures.id
   * @type {number}
   * @memberof PrefectureWithJobCount
   */
  prefecture_master_id: number
  /**
   * 表示名
   * @type {string}
   * @memberof PrefectureWithJobCount
   */
  name: string
  /**
   * 求人数
   * @type {number}
   * @memberof PrefectureWithJobCount
   */
  job_count: number
}
/**
 * 都道府県を求人数付きで取得。求人数0件の都道府県も含まれる
 * @export
 * @interface PrefecturesWithJobCount
 */
export interface PrefecturesWithJobCount {
  /**
   *
   * @type {Array<PrefecturesWithJobCountRegionsInner>}
   * @memberof PrefecturesWithJobCount
   */
  regions: Array<PrefecturesWithJobCountRegionsInner>
}
/**
 *
 * @export
 * @interface PrefecturesWithJobCountRegionsInner
 */
export interface PrefecturesWithJobCountRegionsInner {
  /**
   * master_of_regions.id
   * @type {number}
   * @memberof PrefecturesWithJobCountRegionsInner
   */
  region_master_id: number
  /**
   * 表示名（master_of_regions.name）
   * @type {string}
   * @memberof PrefecturesWithJobCountRegionsInner
   */
  name: string
  /**
   *
   * @type {Array<PrefectureWithJobCount>}
   * @memberof PrefecturesWithJobCountRegionsInner
   */
  prefectures: Array<PrefectureWithJobCount>
}
/**
 * 募集企業情報
 * @export
 * @interface RecruitmentCompany
 */
export interface RecruitmentCompany {
  /**
   * AgentBankDBでのrecruitment_company_id
   * @type {number}
   * @memberof RecruitmentCompany
   */
  ab_recruitment_company_id: number
  /**
   * 会社名
   * @type {string}
   * @memberof RecruitmentCompany
   */
  name: string
  /**
   * 本社住所
   * @type {string}
   * @memberof RecruitmentCompany
   */
  address?: string | null
  /**
   * 設立年
   * @type {number}
   * @memberof RecruitmentCompany
   */
  establishment_year: number | null
  /**
   * 設立月
   * @type {number}
   * @memberof RecruitmentCompany
   */
  establishment_month: number | null
  /**
   * 従業員数
   * @type {number}
   * @memberof RecruitmentCompany
   */
  number_of_employees?: number | null
  /**
   * 事業概要
   * @type {string}
   * @memberof RecruitmentCompany
   */
  description_of_business: string
  /**
   * 会社概要
   * @type {string}
   * @memberof RecruitmentCompany
   */
  overview: string | null
  /**
   * 会社HP
   * @type {string}
   * @memberof RecruitmentCompany
   */
  site_url?: string | null
  /**
   * 業界のリスト
   * @type {Array<RecruitmentCompanyIndustry>}
   * @memberof RecruitmentCompany
   */
  recruitment_company_industries: Array<RecruitmentCompanyIndustry>
}
/**
 * 募集企業の業界
 * @export
 * @interface RecruitmentCompanyIndustry
 */
export interface RecruitmentCompanyIndustry {
  /**
   *
   * @type {MediumMasterListItem}
   * @memberof RecruitmentCompanyIndustry
   */
  recruitment_company_industry_lg: MediumMasterListItem
  /**
   *
   * @type {MediumMasterListItem}
   * @memberof RecruitmentCompanyIndustry
   */
  recruitment_company_industry_sm: MediumMasterListItem
}
/**
 *
 * @export
 * @interface Station
 */
export interface Station {
  /**
   * AgentBankDBでのstation.id
   * @type {number}
   * @memberof Station
   */
  ab_station_id: number
  /**
   * 駅からの時間
   * @type {string}
   * @memberof Station
   */
  time_from_station_text: string
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary 連絡先が使用可能がチェックするAPI
     * @param {string} email
     * @param {string} phoneNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateContacts: async (
      email: string,
      phoneNumber: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'email' is not null or undefined
      assertParamExists('validateContacts', 'email', email)
      // verify required parameter 'phoneNumber' is not null or undefined
      assertParamExists('validateContacts', 'phoneNumber', phoneNumber)
      const localVarPath = `/auth/validate_contacts`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (email !== undefined) {
        localVarQueryParameter['email'] = email
      }

      if (phoneNumber !== undefined) {
        localVarQueryParameter['phone_number'] = phoneNumber
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary 連絡先が使用可能がチェックするAPI
     * @param {string} email
     * @param {string} phoneNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async validateContacts(
      email: string,
      phoneNumber: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.validateContacts(
        email,
        phoneNumber,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AuthApiFp(configuration)
  return {
    /**
     *
     * @summary 連絡先が使用可能がチェックするAPI
     * @param {string} email
     * @param {string} phoneNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateContacts(email: string, phoneNumber: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .validateContacts(email, phoneNumber, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
  /**
   *
   * @summary 連絡先が使用可能がチェックするAPI
   * @param {string} email
   * @param {string} phoneNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public validateContacts(email: string, phoneNumber: string, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .validateContacts(email, phoneNumber, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * JobApi - axios parameter creator
 * @export
 */
export const JobApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary 求人をID指定で一括取得するAPI
     * @param {Array<string>} jobIds 求人ID列
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchJobsByIds: async (
      jobIds: Array<string>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobIds' is not null or undefined
      assertParamExists('fetchJobsByIds', 'jobIds', jobIds)
      const localVarPath = `/job/fetch`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (jobIds) {
        localVarQueryParameter['job_ids[]'] = jobIds
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 求人を単体取得するAPI
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getJob: async (jobId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('getJob', 'jobId', jobId)
      const localVarPath = `/job/{job_id}`.replace(
        `{${'job_id'}}`,
        encodeURIComponent(String(jobId)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 検索にHitする求人数を取得するAPI
     * @param {Array<number>} [workLocationPrefecture] 勤務地（都道府県）
     * @param {Array<number>} [jobCategory] 職種
     * @param {Array<number>} [particularCondition] こだわり条件
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getJobCount: async (
      workLocationPrefecture?: Array<number>,
      jobCategory?: Array<number>,
      particularCondition?: Array<number>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/job/count`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (workLocationPrefecture) {
        localVarQueryParameter['work_location_prefecture[]'] = workLocationPrefecture
      }

      if (jobCategory) {
        localVarQueryParameter['job_category[]'] = jobCategory
      }

      if (particularCondition) {
        localVarQueryParameter['particular_condition[]'] = particularCondition
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 求人一覧を取得するAPI
     * @param {number} [page] ページ番号
     * @param {Array<number>} [workLocationPrefecture] 勤務地（都道府県）
     * @param {Array<number>} [jobCategory] 職種
     * @param {Array<number>} [particularCondition] こだわり条件
     * @param {number} [companyId] 企業ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getJobList: async (
      page?: number,
      workLocationPrefecture?: Array<number>,
      jobCategory?: Array<number>,
      particularCondition?: Array<number>,
      companyId?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/job`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (page !== undefined) {
        localVarQueryParameter['page'] = page
      }

      if (workLocationPrefecture) {
        localVarQueryParameter['work_location_prefecture[]'] = workLocationPrefecture
      }

      if (jobCategory) {
        localVarQueryParameter['job_category[]'] = jobCategory
      }

      if (particularCondition) {
        localVarQueryParameter['particular_condition[]'] = particularCondition
      }

      if (companyId !== undefined) {
        localVarQueryParameter['company_id'] = companyId
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 求人を単体取得するAPI
     * @param {string} jobId
     * @param {string} masterPrefectureId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLimitedWorkLocationJob: async (
      jobId: string,
      masterPrefectureId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('getLimitedWorkLocationJob', 'jobId', jobId)
      // verify required parameter 'masterPrefectureId' is not null or undefined
      assertParamExists('getLimitedWorkLocationJob', 'masterPrefectureId', masterPrefectureId)
      const localVarPath = `/job/{job_id}/{master_prefecture_id}`
        .replace(`{${'job_id'}}`, encodeURIComponent(String(jobId)))
        .replace(`{${'master_prefecture_id'}}`, encodeURIComponent(String(masterPrefectureId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 掲載検討企業プレビュー用に求人情報を取得するAPI
     * @param {string} previewToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    previewJob: async (
      previewToken: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'previewToken' is not null or undefined
      assertParamExists('previewJob', 'previewToken', previewToken)
      const localVarPath = `/job/preview/{preview_token}`.replace(
        `{${'preview_token'}}`,
        encodeURIComponent(String(previewToken)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * JobApi - functional programming interface
 * @export
 */
export const JobApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = JobApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary 求人をID指定で一括取得するAPI
     * @param {Array<string>} jobIds 求人ID列
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fetchJobsByIds(
      jobIds: Array<string>,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobList>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.fetchJobsByIds(jobIds, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary 求人を単体取得するAPI
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getJob(
      jobId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetJob200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getJob(jobId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary 検索にHitする求人数を取得するAPI
     * @param {Array<number>} [workLocationPrefecture] 勤務地（都道府県）
     * @param {Array<number>} [jobCategory] 職種
     * @param {Array<number>} [particularCondition] こだわり条件
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getJobCount(
      workLocationPrefecture?: Array<number>,
      jobCategory?: Array<number>,
      particularCondition?: Array<number>,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Count>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getJobCount(
        workLocationPrefecture,
        jobCategory,
        particularCondition,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary 求人一覧を取得するAPI
     * @param {number} [page] ページ番号
     * @param {Array<number>} [workLocationPrefecture] 勤務地（都道府県）
     * @param {Array<number>} [jobCategory] 職種
     * @param {Array<number>} [particularCondition] こだわり条件
     * @param {number} [companyId] 企業ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getJobList(
      page?: number,
      workLocationPrefecture?: Array<number>,
      jobCategory?: Array<number>,
      particularCondition?: Array<number>,
      companyId?: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobList>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getJobList(
        page,
        workLocationPrefecture,
        jobCategory,
        particularCondition,
        companyId,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary 求人を単体取得するAPI
     * @param {string} jobId
     * @param {string} masterPrefectureId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLimitedWorkLocationJob(
      jobId: string,
      masterPrefectureId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetJob200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getLimitedWorkLocationJob(
        jobId,
        masterPrefectureId,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary 掲載検討企業プレビュー用に求人情報を取得するAPI
     * @param {string} previewToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async previewJob(
      previewToken: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Job>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.previewJob(previewToken, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * JobApi - factory interface
 * @export
 */
export const JobApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = JobApiFp(configuration)
  return {
    /**
     *
     * @summary 求人をID指定で一括取得するAPI
     * @param {Array<string>} jobIds 求人ID列
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fetchJobsByIds(jobIds: Array<string>, options?: any): AxiosPromise<JobList> {
      return localVarFp.fetchJobsByIds(jobIds, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 求人を単体取得するAPI
     * @param {string} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getJob(jobId: string, options?: any): AxiosPromise<GetJob200Response> {
      return localVarFp.getJob(jobId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 検索にHitする求人数を取得するAPI
     * @param {Array<number>} [workLocationPrefecture] 勤務地（都道府県）
     * @param {Array<number>} [jobCategory] 職種
     * @param {Array<number>} [particularCondition] こだわり条件
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getJobCount(
      workLocationPrefecture?: Array<number>,
      jobCategory?: Array<number>,
      particularCondition?: Array<number>,
      options?: any,
    ): AxiosPromise<Count> {
      return localVarFp
        .getJobCount(workLocationPrefecture, jobCategory, particularCondition, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 求人一覧を取得するAPI
     * @param {number} [page] ページ番号
     * @param {Array<number>} [workLocationPrefecture] 勤務地（都道府県）
     * @param {Array<number>} [jobCategory] 職種
     * @param {Array<number>} [particularCondition] こだわり条件
     * @param {number} [companyId] 企業ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getJobList(
      page?: number,
      workLocationPrefecture?: Array<number>,
      jobCategory?: Array<number>,
      particularCondition?: Array<number>,
      companyId?: number,
      options?: any,
    ): AxiosPromise<JobList> {
      return localVarFp
        .getJobList(
          page,
          workLocationPrefecture,
          jobCategory,
          particularCondition,
          companyId,
          options,
        )
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 求人を単体取得するAPI
     * @param {string} jobId
     * @param {string} masterPrefectureId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLimitedWorkLocationJob(
      jobId: string,
      masterPrefectureId: string,
      options?: any,
    ): AxiosPromise<GetJob200Response> {
      return localVarFp
        .getLimitedWorkLocationJob(jobId, masterPrefectureId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 掲載検討企業プレビュー用に求人情報を取得するAPI
     * @param {string} previewToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    previewJob(previewToken: string, options?: any): AxiosPromise<Job> {
      return localVarFp
        .previewJob(previewToken, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * JobApi - object-oriented interface
 * @export
 * @class JobApi
 * @extends {BaseAPI}
 */
export class JobApi extends BaseAPI {
  /**
   *
   * @summary 求人をID指定で一括取得するAPI
   * @param {Array<string>} jobIds 求人ID列
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApi
   */
  public fetchJobsByIds(jobIds: Array<string>, options?: AxiosRequestConfig) {
    return JobApiFp(this.configuration)
      .fetchJobsByIds(jobIds, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 求人を単体取得するAPI
   * @param {string} jobId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApi
   */
  public getJob(jobId: string, options?: AxiosRequestConfig) {
    return JobApiFp(this.configuration)
      .getJob(jobId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 検索にHitする求人数を取得するAPI
   * @param {Array<number>} [workLocationPrefecture] 勤務地（都道府県）
   * @param {Array<number>} [jobCategory] 職種
   * @param {Array<number>} [particularCondition] こだわり条件
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApi
   */
  public getJobCount(
    workLocationPrefecture?: Array<number>,
    jobCategory?: Array<number>,
    particularCondition?: Array<number>,
    options?: AxiosRequestConfig,
  ) {
    return JobApiFp(this.configuration)
      .getJobCount(workLocationPrefecture, jobCategory, particularCondition, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 求人一覧を取得するAPI
   * @param {number} [page] ページ番号
   * @param {Array<number>} [workLocationPrefecture] 勤務地（都道府県）
   * @param {Array<number>} [jobCategory] 職種
   * @param {Array<number>} [particularCondition] こだわり条件
   * @param {number} [companyId] 企業ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApi
   */
  public getJobList(
    page?: number,
    workLocationPrefecture?: Array<number>,
    jobCategory?: Array<number>,
    particularCondition?: Array<number>,
    companyId?: number,
    options?: AxiosRequestConfig,
  ) {
    return JobApiFp(this.configuration)
      .getJobList(
        page,
        workLocationPrefecture,
        jobCategory,
        particularCondition,
        companyId,
        options,
      )
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 求人を単体取得するAPI
   * @param {string} jobId
   * @param {string} masterPrefectureId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApi
   */
  public getLimitedWorkLocationJob(
    jobId: string,
    masterPrefectureId: string,
    options?: AxiosRequestConfig,
  ) {
    return JobApiFp(this.configuration)
      .getLimitedWorkLocationJob(jobId, masterPrefectureId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 掲載検討企業プレビュー用に求人情報を取得するAPI
   * @param {string} previewToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JobApi
   */
  public previewJob(previewToken: string, options?: AxiosRequestConfig) {
    return JobApiFp(this.configuration)
      .previewJob(previewToken, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * MaintenanceApi - axios parameter creator
 * @export
 */
export const MaintenanceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary 主にメンテナンスモード中にアクセスされるエンドポイント
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaintenanceStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/maintenance`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * MaintenanceApi - functional programming interface
 * @export
 */
export const MaintenanceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = MaintenanceApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary 主にメンテナンスモード中にアクセスされるエンドポイント
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMaintenanceStatus(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaintenanceStatus>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getMaintenanceStatus(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * MaintenanceApi - factory interface
 * @export
 */
export const MaintenanceApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = MaintenanceApiFp(configuration)
  return {
    /**
     *
     * @summary 主にメンテナンスモード中にアクセスされるエンドポイント
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMaintenanceStatus(options?: any): AxiosPromise<MaintenanceStatus> {
      return localVarFp.getMaintenanceStatus(options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * MaintenanceApi - object-oriented interface
 * @export
 * @class MaintenanceApi
 * @extends {BaseAPI}
 */
export class MaintenanceApi extends BaseAPI {
  /**
   *
   * @summary 主にメンテナンスモード中にアクセスされるエンドポイント
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MaintenanceApi
   */
  public getMaintenanceStatus(options?: AxiosRequestConfig) {
    return MaintenanceApiFp(this.configuration)
      .getMaintenanceStatus(options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * MasterApi - axios parameter creator
 * @export
 */
export const MasterApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary master情報を取得するためのAPI
     * @param {Array<string>} category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMasterList: async (
      category: Array<string>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'category' is not null or undefined
      assertParamExists('getMasterList', 'category', category)
      const localVarPath = `/master`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (category) {
        localVarQueryParameter['category[]'] = category
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 都道府県を求人数つきで取得するAPI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPrefecturesWithJobCount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/master/prefecture/job_count`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary LINEからのWebHookを受けるためのAPI
     * @param {LineWebhookRequest} [lineWebhookRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postLineWebhook: async (
      lineWebhookRequest?: LineWebhookRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/webhook/line`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        lineWebhookRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * MasterApi - functional programming interface
 * @export
 */
export const MasterApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = MasterApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary master情報を取得するためのAPI
     * @param {Array<string>} category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMasterList(
      category: Array<string>,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LargeMasterListItem>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getMasterList(category, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary 都道府県を求人数つきで取得するAPI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPrefecturesWithJobCount(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrefecturesWithJobCount>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPrefecturesWithJobCount(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary LINEからのWebHookを受けるためのAPI
     * @param {LineWebhookRequest} [lineWebhookRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postLineWebhook(
      lineWebhookRequest?: LineWebhookRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postLineWebhook(
        lineWebhookRequest,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * MasterApi - factory interface
 * @export
 */
export const MasterApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = MasterApiFp(configuration)
  return {
    /**
     *
     * @summary master情報を取得するためのAPI
     * @param {Array<string>} category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMasterList(
      category: Array<string>,
      options?: any,
    ): AxiosPromise<Array<LargeMasterListItem>> {
      return localVarFp.getMasterList(category, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 都道府県を求人数つきで取得するAPI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPrefecturesWithJobCount(options?: any): AxiosPromise<PrefecturesWithJobCount> {
      return localVarFp
        .getPrefecturesWithJobCount(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary LINEからのWebHookを受けるためのAPI
     * @param {LineWebhookRequest} [lineWebhookRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postLineWebhook(lineWebhookRequest?: LineWebhookRequest, options?: any): AxiosPromise<void> {
      return localVarFp
        .postLineWebhook(lineWebhookRequest, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * MasterApi - object-oriented interface
 * @export
 * @class MasterApi
 * @extends {BaseAPI}
 */
export class MasterApi extends BaseAPI {
  /**
   *
   * @summary master情報を取得するためのAPI
   * @param {Array<string>} category
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MasterApi
   */
  public getMasterList(category: Array<string>, options?: AxiosRequestConfig) {
    return MasterApiFp(this.configuration)
      .getMasterList(category, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 都道府県を求人数つきで取得するAPI
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MasterApi
   */
  public getPrefecturesWithJobCount(options?: AxiosRequestConfig) {
    return MasterApiFp(this.configuration)
      .getPrefecturesWithJobCount(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary LINEからのWebHookを受けるためのAPI
   * @param {LineWebhookRequest} [lineWebhookRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MasterApi
   */
  public postLineWebhook(lineWebhookRequest?: LineWebhookRequest, options?: AxiosRequestConfig) {
    return MasterApiFp(this.configuration)
      .postLineWebhook(lineWebhookRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * SitemapApi - axios parameter creator
 * @export
 */
export const SitemapApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary sitemap.xmlの生成のためにNext.jsに渡したい値を一括で提供するAPI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    forSitemap: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/for_sitemap`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * SitemapApi - functional programming interface
 * @export
 */
export const SitemapApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SitemapApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary sitemap.xmlの生成のためにNext.jsに渡したい値を一括で提供するAPI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async forSitemap(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForSitemap>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.forSitemap(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * SitemapApi - factory interface
 * @export
 */
export const SitemapApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SitemapApiFp(configuration)
  return {
    /**
     *
     * @summary sitemap.xmlの生成のためにNext.jsに渡したい値を一括で提供するAPI
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    forSitemap(options?: any): AxiosPromise<ForSitemap> {
      return localVarFp.forSitemap(options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * SitemapApi - object-oriented interface
 * @export
 * @class SitemapApi
 * @extends {BaseAPI}
 */
export class SitemapApi extends BaseAPI {
  /**
   *
   * @summary sitemap.xmlの生成のためにNext.jsに渡したい値を一括で提供するAPI
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SitemapApi
   */
  public forSitemap(options?: AxiosRequestConfig) {
    return SitemapApiFp(this.configuration)
      .forSitemap(options)
      .then((request) => request(this.axios, this.basePath))
  }
}
