/* eslint-disable no-console */
import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { datadogRum } from '@datadog/browser-rum'
import * as Sentry from '@sentry/nextjs'
import { AxiosError } from 'axios'
import { initializeApp } from 'firebase/app'
import {
  ApplicationVerifier,
  Auth,
  ConfirmationResult,
  connectAuthEmulator,
  getAuth,
  RecaptchaVerifier,
  signInWithCustomToken as firebaseSignInWithCustomToken,
  signInWithPhoneNumber,
} from 'firebase/auth'
import { useAtomValue, useSetAtom } from 'jotai'
import { useHydrateAtoms } from 'jotai/utils'
import { NextRouter, useRouter } from 'next/router'

import {
  addSmsLoginMethodApi,
  checkValidateContactApi,
  deleteUserApi,
  getMeApi,
  lineAddLoginMethodApi,
  lineLoginApi,
  loginApi,
  logoutApi,
  registrationApi,
  sessionInitializeApi,
} from '@/api/authApi'
import { firebaseConfig, firebaseEmulating } from '@/config'
import { authMeAtom, initialAuthState } from '@/globalState/auth'
import { useToastWrapper } from '@/modules/hooks/useToastWrapper'
import { sendClarityFiredEvent, sendClarityUserId } from '@/modules/utils/gtm/clarityEventActions'
import { sendGASignUp, setGAConfig } from '@/modules/utils/gtm/gaEventActions'
import { MeTalent, RegistrationRequest } from '@/oas/talent/api'

import { KeptJobsCookie, useKeepJob } from './useKeepJob'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    recaptchaVerifier: RecaptchaVerifier
    confirmationResult: ConfirmationResult
    recaptchaVerifierCallback: (response: any) => void
  }
}

const app = initializeApp(firebaseConfig)
export const firebaseAuth = getAuth(app)
firebaseAuth.languageCode = 'ja'

if (firebaseEmulating) {
  connectAuthEmulator(firebaseAuth, 'http://localhost:9099')
}

export const useAuth = () => {
  const setMe = useSetAtom(authMeAtom)
  const { success, error } = useToastWrapper()
  const [cookies, setCookies] = useCookies(['keptJobs', 'firebase-session-cookie'])
  // Firebaseの初期化
  let recaptchaVerifierElement: HTMLElement | null = null

  const { postKeepJobs } = useKeepJob()

  const [isConfirm, setIsConfirm] = useState(false)
  const [isVerifyLoading, setIsVerifyLoading] = useState(false)
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [confirmFunc, setConfirmFunc] = useState<ConfirmationResult>()
  const [idToken, setIdToken] = useState<string>()
  const [currentRecaptcha, setCurrentRecaptcha] = useState<RecaptchaVerifier | null>(null)

  const setAuthInfo = (data: MeTalent) => {
    setMe({
      me: data,
      loading: false,
    })
    // GA4イベント送信
    setGAConfig({ talent_id: data.id })
    // Sentryにユーザー情報を送信
    Sentry.setUser({
      id: data.id,
    })
    datadogRum.setUser({
      id: data.id.toString(),
    })
    sendClarityUserId()
  }

  /**
   * signInWithPhoneNumberのメソッド
   * ここで電話番号を使用してSMSを送信する処理を実施している
   * @param auth
   * @param phoneNumber
   * @param appVerifier
   */
  const onSignInSubmit = (auth: Auth, phoneNumber: string, appVerifier: ApplicationVerifier) => {
    const formattedPhoneNumber = `+81${phoneNumber}` // 例: 日本の場合は"+81"を追加する
    signInWithPhoneNumber(auth, formattedPhoneNumber, appVerifier)
      .then((confirmationResult) => {
        setIsVerifyLoading(false)
        // 確認コード入力画面へと表示を切り替えている
        setIsConfirm(true)
        // 確認コードを入れて叩く関数をstateに保存
        setConfirmFunc(confirmationResult)
        success('認証コードを送信しました。')
      })
      .catch((err) => {
        setIsVerifyLoading(false)
        console.error(err)
        const code = err.code as string
        if (code.includes('too-many-requests')) {
          error('認証コード送信の上限に達しました。時間を置いて再度お試しください。')
          return
        }
        error('エラーが発生しました。画面を更新して再度お試しください。')
      })
  }

  /**
   * 電話番号を入力した後のボタン押下時処理
   * @param phoneNumber
   * @returns
   */
  const submit = (phoneNumber: string) => {
    setIsVerifyLoading(true)
    // 契機となるボタンの要素を取得
    recaptchaVerifierElement = document.getElementById('sign-in-button')
    if (!recaptchaVerifierElement) {
      // エラーハンドリング：指定したIDの要素が存在しない場合
      console.error('no button')
      error('エラーが発生しました。画面を更新して再度お試しください。')
      setIsVerifyLoading(false)
      return
    }

    // RecaptchaVerifier(認証する際に必要なやつ)を作成し描画する
    const recaptchaVerifier = new RecaptchaVerifier(firebaseAuth, recaptchaVerifierElement, {
      size: 'invisible',
    })
    recaptchaVerifier.render()

    // コールバック関数
    const signInSubmitCallback = () => {
      // reCAPTCHA solved, allow signInWithPhoneNumber.
      onSignInSubmit(firebaseAuth, phoneNumber, recaptchaVerifier)
    }
    // verify
    recaptchaVerifier.verify().then(signInSubmitCallback)
    window.recaptchaVerifier = recaptchaVerifier
    window.recaptchaVerifierCallback = signInSubmitCallback
  }

  /**
   * 認証コード再送信用処理
   */
  const resendVerificationCode = async (phoneNumber: string) => {
    // 2回目の再送信以降にstateに保存されていたらrecaptchaが描画されているのでコード再送信のみする
    if (currentRecaptcha) {
      onSignInSubmit(firebaseAuth, phoneNumber, currentRecaptcha)
      return
    }

    // 以下はsubmitと同じ処理
    recaptchaVerifierElement = document.getElementById('resend-button')
    if (!recaptchaVerifierElement) {
      // エラーハンドリング：指定したIDの要素が存在しない場合
      console.error('no button')
      error('エラーが発生しました。画面を更新して再度お試しください。')
      return
    }

    const recaptchaVerifier = new RecaptchaVerifier(firebaseAuth, recaptchaVerifierElement, {
      size: 'invisible',
    })
    setCurrentRecaptcha(recaptchaVerifier)
    recaptchaVerifier.render()

    onSignInSubmit(firebaseAuth, phoneNumber, recaptchaVerifier)
  }

  /**
   * 未認証時にキープしている求人があった場合に全てお気に入りに登録する
   */
  const keepJobRegistration = () => {
    const cookie = cookies.keptJobs as KeptJobsCookie[]
    if (cookie && cookie.length !== 0) {
      const jobIds = cookie.map((v) => {
        return { job_id: v.jobId, kept_at: v.keepTime }
      })
      postKeepJobs(jobIds).then((res) => {
        if (res) {
          setCookies('keptJobs', [], {
            maxAge: 90 * 24 * 60 * 60,
            path: '/',
          })
        }
      })
    }
  }

  const fetchMe = async () => {
    const res = await getMeApi()
      .then((res) => {
        setMe({
          me: res.data,
          loading: false,
        })
        setAuthInfo(res.data)
        return {
          status: res.status,
          data: res.data,
        }
      })
      .catch((err: AxiosError) => {
        setMe({
          me: null,
          loading: false,
        })
        return {
          status: err.response?.status,
          data: null,
        }
      })
    if (res.status === 401) {
      firebaseAuth.signOut()
    }
    return res
  }

  const login = async (token: string) => {
    const res = await loginApi(token)
      .then((res) => {
        if (res.status === 200) {
          keepJobRegistration()
          // GA4イベント送信
          setGAConfig({ talent_id: res.data })
          // Sentryにユーザー情報を送信
          Sentry.setUser({
            id: res.data,
          })
          datadogRum.setUser({
            id: res.data.toString(),
          })
          sendClarityUserId()
        }
        return res.status
      })
      .catch((res: AxiosError) => res.response?.status)
    return res
  }

  const sessionInitialize = () => {
    sessionInitializeApi()
  }

  const lineLogin = () => {
    lineLoginApi()
      .then((res) => {
        window.location.href = res.data.line_login_url
      })
      .catch(() => {
        error('ログインに失敗しました。')
      })
  }

  const lineAddLoginMethod = () => {
    lineAddLoginMethodApi()
      .then((res) => {
        window.location.href = res.data.line_login_url
      })
      .catch(() => {
        error('ログインに失敗しました。')
      })
  }

  const signInWithCustomToken = async (customToken: string) => {
    return firebaseSignInWithCustomToken(firebaseAuth, customToken).then((userCredential) =>
      userCredential.user.getIdToken(),
    )
  }

  const registration = async (
    values: RegistrationRequest,
    idToken: string,
    sourceProduct: string,
  ) => {
    const utmParameters = window.dataLayer
      ? window.dataLayer.find((obj) => obj.event === 'dataLayerSync')
      : null
    if (utmParameters) {
      values.utm_source = utmParameters.utm_source
      values.utm_medium = utmParameters.utm_medium
      values.utm_campaign = utmParameters.utm_campaign
      values.utm_term = utmParameters.utm_term
      values.utm_content = utmParameters.utm_content
    }
    const res = await registrationApi(values, idToken)
      .then(async (res) => {
        if (res.status !== 201) {
          error('登録に失敗しました。')
          return { isSuccess: false }
        }
        await fetchMe()
        keepJobRegistration()
        // GA4イベント送信
        setGAConfig({ talent_id: res.data })
        // GA4イベント送信
        sendClarityFiredEvent('registration_step_1_finished')
        sendClarityUserId()
        sendGASignUp(sourceProduct)
        return { isSuccess: true }
      })
      .catch((err) => {
        if (err.response?.status === 422) {
          return {
            isSuccess: false,
            errorText: err.response.data.errors as { [key: string]: string }[],
          }
        }
        console.error(err)
        error('登録に失敗しました。')
        return { isSuccess: false }
      })
    return res
  }

  const addSmsLoginMethod = (
    idToken: string,
  ): Promise<{
    isSuccess: boolean
  }> => {
    return addSmsLoginMethodApi(idToken)
      .then((res) => {
        if (res.status === 401) {
          error('認証コードが有効期限切れか正しくありません')
          return { isSuccess: false }
        }
        if (res.status === 409) {
          error('SMS認証が登録済みのアカウントです')
          return { isSuccess: false }
        }
        if (res.status !== 200) {
          error('SMS認証追加に失敗しました')
          return { isSuccess: false }
        }

        return { isSuccess: true }
      })
      .catch((err) => {
        console.error(err)
        error('SMS認証追加に失敗しました。')
        return { isSuccess: false }
      })
  }

  const confirmError = (res: any) => {
    const errorMessage = res.message as string
    if (errorMessage.includes('too-many-attempts')) {
      error('認証コード送信の上限に達しました。時間を置いて再度お試しください。')
      return
    }
    if (errorMessage.includes('invalid-verification-code')) {
      error('認証コードに誤りがあります。確認の上再度送信してください。')
      return
    }
    error('エラーが発生しました。画面を更新して再度実施してください。')
    console.error(res.message)
  }

  const logout = async () => {
    setIsButtonLoading(true)
    const res = await logoutApi().then((res) => {
      if (res.status === 200) {
        firebaseAuth.signOut()
        // GA4イベント送信
        setGAConfig({ talent_id: null })
        // Sentryにユーザー情報を削除
        Sentry.setUser(null)
        datadogRum.clearUser()
        success('ログアウトされました。')
        return true
      }
      error('画面を更新して再度やり直してください。')
      return false
    })
    setIsButtonLoading(false)
    return res
  }

  const deleteUser = async () => {
    setIsButtonLoading(true)
    const res = await deleteUserApi().then((res) => {
      if (res.status === 200) {
        firebaseAuth.signOut()
        // GA4イベント送信
        setGAConfig({ talent_id: null })
        // Sentryにユーザー情報を削除
        Sentry.setUser(null)
        datadogRum.clearUser()
        success('退会処理が完了しました。')
        return true
      }
      error('画面を更新して再度やり直してください。')
      return false
    })
    setIsButtonLoading(false)
    return res
  }

  /**
   * メールアドレスと電話番号を使用してすでに登録がないかチェックする
   * @returns boolean
   */
  const checkContact = async (email: string, phoneNumber: string) => {
    const res = await checkValidateContactApi(email, phoneNumber)
      .then(() => false)
      .catch(() => true)
    return res
  }

  return {
    setAuthInfo,
    firebaseAuth,
    isVerifyLoading,
    setIsVerifyLoading,
    isConfirm,
    setIsConfirm,
    confirmFunc,
    submit,
    idToken,
    setIdToken,
    resendVerificationCode,
    login,
    sessionInitialize,
    lineLogin,
    lineAddLoginMethod,
    fetchMe,
    registration,
    addSmsLoginMethod,
    confirmError,
    logout,
    deleteUser,
    isButtonLoading,
    setIsButtonLoading,
    checkContact,
    signInWithCustomToken,
  }
}

const loginUserRedirectPath = (pathname: NextRouter['pathname']) => {
  const authenticateRequiredPages = [/login/]
  return authenticateRequiredPages.some((v) => v.test(pathname))
}

const isAuthenticateRequiredPages = (pathname: NextRouter['pathname']) => {
  const s = pathname.slice(1)
  if (s === '') {
    return false
  }

  const authenticateRequiredPages = [
    /account/,
    /account\/*/,
    /scout/,
    /scout\/*/,
    /inquiry/,
    /inquiry\/*/,
  ]
  return authenticateRequiredPages.some((v) => v.test(s))
}

/**
 * 認証状態をチェック
 * SSRで取得したログイン情報があればそれを使う
 * SSRで取得したログイン情報がない場合はAPIで認証状態をチェックする
 *
 * @param initialMeValue SSRで取得したログイン情報があれば入れる
 */
export const useAuthCheck = (initialMeValue?: MeTalent | null) => {
  const initialAuthMeAtom = initialMeValue
    ? {
        me: initialMeValue,
        loading: false,
      }
    : initialAuthState
  useHydrateAtoms([[authMeAtom, initialAuthMeAtom]])
  const meAtom = useAtomValue(authMeAtom)
  const router = useRouter()

  const { fetchMe, setAuthInfo } = useAuth()

  useEffect(() => {
    if (!initialMeValue && meAtom.loading) {
      fetchMe()
    }
    if (initialMeValue) {
      setAuthInfo(initialMeValue)
    }
  }, [initialMeValue])

  useEffect(() => {
    if (isAuthenticateRequiredPages(router.pathname) && !meAtom.loading && !meAtom.me) {
      router.push('/login')
    }

    const handler = () => {
      if (loginUserRedirectPath(router.pathname) && meAtom.me) {
        router.push('/')
      }

      if (isAuthenticateRequiredPages(router.pathname) && !meAtom.loading && !meAtom.me) {
        router.push('/login')
      }
    }
    router.events.on('routeChangeComplete', handler)

    return () => {
      router.events.off('routeChangeComplete', handler)
    }
  }, [router, meAtom])

  return { me: meAtom.me, loading: meAtom.loading }
}
